
import ApiError from '@/services/api/models/ApiError';
import User from '@/store/modules/User';
import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ChangeEmail extends Vue {
  public error: ApiError | null = null;
  public attemptSubmit: boolean | null = null;
  public loading = true;
  public verified = false;
  public unresolvableError = false;
  public errorMessage = 'The email could not be verified. Please contact Levno';

  mounted() {
    this.verify();
  }

  public async verify(): Promise<void> {
    if (!this.validateLink()) {
      this.attemptSubmit = false;
      this.unresolvableError = true;
      this.loading = false;

      this.errorMessage =
        'The email could not be verified. You are attempting to use and expired link';
      return;
    }
    this.error = null;
    try {
      await User.changeEmail({
        userId: this.$route.params.userId,
        expires: this.$route.query.expires as string,
        signature: this.$route.query.signature as string
      });
      this.loading = false;
      this.verified = true;
    } catch (error) {
      if (
        (error as ApiError).status == 401 ||
        (error as ApiError).status == 404
      ) {
        this.unresolvableError = true;
        this.verified = false;
        this.loading = false;
      } else {
        this.error = error as ApiError;
      }
    }
  }

  public validateLink(): boolean {
    const now = dayjs().unix();
    return +this.$route.query.expires > now;
  }
}
